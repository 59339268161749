export enum MenuId {
  DASHBOARD_ID = 4,
  ORDER_FORM_ID = 5,
  APK_ID = 6,
  APK_ID_KMOB = 15,

  AKK_ID = 7,
  AKK_ID_KMOB = 17,

  ASSIGN_EDIT_ID = 8,
  RECALCULATE_ID = 9,
  DE_A_ID = 10,
  ACTIVITY_ID = 11,
  CUSTOMER_MANAGEMENT_ID = 12,
  AGENT_VISIT_ID = 13,
  TAKSASI_ID = 18,
  SALES_INCENTIVE_ID = 23,
  CMO_DEDICATED_KPM_ID = 24,
  AGENT_REGISTRATION_ID = 28,
  CALCULATION_SIMULATION_ID = 29,
  DEALER_VISIT_ID = 30,
  APK_SF_ID = 31,
}

export enum PrivilegeId {
  // DASHBOARD KMB
  KMB_DASHBOARD_ID = 11,
  KMB_DASHBOARD_MO_CMO_ID = 13,

  // DASHBOARD KMOB
  KMOB_DASHBOARD_ID = 4,

  // DASHBOARD WG
  WG_DASHBOARD_ID = 55,

  // ORDER FORM KMB
  KMB_KMB_LIST_ORDER_FORM_ID = 14,
  KMB_FORM_SUBMISSION_INHOUSE_ID = 15,
  KMB_FORM_SUBMISSION_AGENT_ID = 16,
  KMB_FORM_SUBMISSION_WEBVIEW_ID = 17,
  KMB_REVIEW_ASSIGN_ORDER_FORM_KPM_ID = 18,
  KMB_REVIEW_ASSIGN_ORDER_FORM_ID = 19,
  KMB_DETAIL_ORDER_FORM_ID = 20,
  KMB_CANCEL_ORDER_ID = 44,
  KMB_VIEW_PDF_PBK_ID = 61,
  KMB_CANCEL_ORDER_INHOUSE_ID = 131,
  KMB_CANCEL_ORDER_AGENT_ID = 132,
  KMB_CANCEL_ORDER_NATIONAL_AGENT_ID = 133,
  KMB_CANCEL_ORDER_TELEMARKETING_ID = 134,
  KMB_CANCEL_ORDER_KPM_ID = 135,
  KMB_CANCEL_ORDER_2W_PRINCIPAL_ID = 136,
  KMB_SUBMISSION_HISTORY_ID = 149,

  // ORDER FORM KMOB
  KMOB_LIST_ORDER_FORM = 64,
  KMOB_DELETE_FORM = 63,
  KMOB_DETAIL_ORDER_FORM = 65,
  KMOB_CANCEL_ORDER_FORM = 66,
  KMOB_REVIEW_ORDER_FORM = 67,
  KMOB_VIEW_PDF_PBK = 68,
  KMOB_SUBMISION_ORDER_FORM = 69,
  KMOB_RETRY_FILTERING_ORDER_FORM = 70,

  // APK KMB
  KMB_LIST_APK_ID = 21,
  KMB_FORM_SUBMISSION_APK_ID = 22,
  KMB_DETAIL_APK_ID = 23,
  KMB_CANCEL_ORDER_APK_ID = 45,
  KMB_CANCEL_ORDER_APK_INHOUSE_ID = 137,
  KMB_CANCEL_ORDER_APK_AGENT_ID = 138,
  KMB_CANCEL_ORDER_APK_NATIONAL_AGENT_ID = 139,
  KMB_CANCEL_ORDER_APK_TELEMARKETING_ID = 140,
  KMB_CANCEL_ORDER_APK_KPM_ID = 141,
  KMB_CANCEL_ORDER_APK_2W_PRINCIPAL_ID = 142,
  KMB_SUBMISSION_HISTORY_APK_ID = 150,

  // APK KMOB
  KMOB_PRINT_APK = 111,
  KMOB_FILTERING_APK = 76,
  KMOB_REVIEW_EDIT__APK = 74,
  KMOB_ASSIGN_SURVEYOR_APK = 75,
  KMOB_DETAIL_APK = 72,
  KMOB_LIST_APK = 71,
  KMOB_CANCEL_APK = 73,
  KMOB_FORM_SUBMISION_APK = 88,
  KMOB_PRINT_PV = 114,

  // AKK KMB
  KMB_LIST_AKK_ID = 24,
  KMB_FORM_SUBMISSION_AKK_ID = 25,
  KMB_DETAIL_AKK_ID = 26,
  KMB_EDIT_AKK_ID = 27,
  KMB_CANCEL_ORDER_AKK_ID = 46,
  KMB_CANCEL_ORDER_AKK_INHOUSE_ID = 143,
  KMB_CANCEL_ORDER_AKK_AGENT_ID = 144,
  KMB_CANCEL_ORDER_AKK_NATIONAL_AGENT_ID = 145,
  KMB_CANCEL_ORDER_AKK_TELEMARKETING_ID = 146,
  KMB_CANCEL_ORDER_AKK_KPM_ID = 147,
  KMB_CANCEL_ORDER_AKK_2W_PRINCIPAL_ID = 148,
  KMB_SUBMISSION_HISTORY_AKK_ID = 151,
  KMB_SUBMIT_TO_LOS_AKK_ID = 152,
  KMB_ASSIGN_EDIT_CMO_AKK_ID = 153,
  KMB_FORM_RECALCULATE_AKK_ID = 154,

  // AKK KMOB
  KMOB_PRINT_AKK = 93,
  KMOB_LIST_AKK = 80,
  KMOB_DETAIL_AKK = 85,
  KMOB_CANCEL_AKK = 86,
  KMOB_VIEW_HISTORY_ORDER_AKK = 91,
  KMOB_SUBMIT_TO_LOS_AKK = 92,
  KMOB_FORM_SUBMISION_AKK = 89,

  // AGENT VISIT KMB
  KMB_LIST_AGENT_VISIT_ID = 57,
  KMB_DETAIL_AGENT_VISIT_ID = 58,
  KMB_AGENT_ACTIVITY_RESPORT_ID = 59,
  KMB_CREATE_AGENT_VISIT_ID = 60,

  // REGISTRASI AGENT KMOB
  KMOB_DETAIL_REGISTRASI_AGENT = 125,
  KMOB_LIST_REGISTRASI_AGENT = 126,
  KMOB_FORM_REGISTRASI_AGENT = 127,
  KMOB_REVIEW_REGISTRASI_AGENT = 129,
  KMOB_APPROVE_REGISTRASI_AGENT = 130,

  // KMOB TAKSASI
  KMOB_LIST_TAKSASI = 81,
  KMOB_FORM_SUBMISION_TAKSASI = 94,
  KMOB_REVIEW_EDIT_PEMBIAYAAN_TAKSASI = 87,
  KMOB_DETAIL_TAKSASI = 82,
  KMOB_CANCEL_TAKSASI = 83,
  KMOB_SUBMIT_TO_LOS_TAKSASI = 84,
  KMOB_VIEW_HISTORY_ORDER_TAKSASI = 95,
  KMOB_PRINT_TAKSASI = 96,
  KMOB_PRINT_LPK_TAKSASI = 113,

  // INCENTIVE SALES WG
  WG_DETAIL_SALES_INCENTIVE_ID = 115,
  WG_LIST_SALES_INCENTIVE_CRO_ID = 116,
  WG_LIST_SALES_INCENTIVE_BRO_ID = 117,
  WG_UPDATE_SALES_INCENTIVE_ID = 118,
  WG_EXPORT_SALES_INCENTIVE_ID = 119,
  WG_UPLOAD_SALES_INCENTIVE_ID = 120,

}
